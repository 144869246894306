import * as React from "react";
import { Helmet } from "react-helmet";

import PageLayout from "../components/Layout/PageLayout";
import PageBlock from "../components/Layout/PageBlock";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { BlockTitle, PageBlockTitle, PageBlockSubtitle, InlineExternalLink } from "../styles/component-stylings";

const PAGE_TITLE = "Disclaimer";

const DisclaimerPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <Helmet>
                <meta name='description' content='Disclaimer for Digital Surveillance Collection from the Australian Federal Police' />
            </Helmet>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>

                <p>All content belongs to DSC except for some external images.</p>
                <p>
                    External images come from:
                    <ul>
                        <li>
                            <InlineExternalLink href='https://www.pexels.com' rel='external nofollow'>
                                Pexels
                            </InlineExternalLink>{" "}
                            - a repository of free-to-use, user-submitted content
                        </li>
                        <li>
                            <InlineExternalLink href='https://unsplash.com' rel='external nofollow'>
                                Unsplash
                            </InlineExternalLink>{" "}
                            - an archive of free-to-use, user-submitted content
                        </li>
                        <li>
                            <InlineExternalLink href='https://getavataaars.com' rel='external nofollow'>
                                Avataars
                            </InlineExternalLink>{" "}
                            - a free SVG avatar generator
                        </li>
                        <li>
                            <InlineExternalLink href='https://app.haikei.app' rel='external nofollow'>
                                Haikei
                            </InlineExternalLink>{" "}
                            - a free SVG background generator
                        </li>
                        <li>
                            <InlineExternalLink href='https://uxwing.com' rel='external nofollow'>
                                UXWing
                            </InlineExternalLink>{" "}
                            - a free collection of SVG icons
                        </li>
                    </ul>
                </p>

                <PageBlockTitle>Home Page</PageBlockTitle>
                <PageBlockSubtitle>Hero Banner</PageBlockSubtitle>
                <ul>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/photo-of-people-doing-handshakes-3183197/' rel='external nofollow'>
                            Photo of people shaking hands at a meeting
                        </InlineExternalLink>
                    </li>
                </ul>
                <PageBlockSubtitle>Who Are DSC</PageBlockSubtitle>
                <ul>
                    <li>
                        {/* https://www.shutterstock.com/image-photo/system-control-room-chief-engineer-thinks-771480601 */}
                        {/* <InlineExternalLink href='https://unsplash.com/photos/vbxyFxlgpjM' rel='external nofollow'>Photo of people around a white table having a meeting</InlineExternalLink> */}
                        <InlineExternalLink href='https://unsplash.com/photos/SYTO3xs06fU' rel='external nofollow'>
                            Photo of laptops and phones
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://unsplash.com/photos/aD6mY43V_QQ' rel='external nofollow'>
                            Photo of smartphones
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://unsplash.com/photos/gUIJ0YszPig' rel='external nofollow'>
                            Photo of person playing on phone and laptop
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://unsplash.com/photos/SYTO3xs06fU' rel='external nofollow'>
                            Photo of disassembling hardware
                        </InlineExternalLink>
                    </li>
                </ul>
                <PageBlockSubtitle>The Work</PageBlockSubtitle>
                <ul>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/black-flat-screen-computer-monitor-1714208/' rel='external nofollow'>
                            Photo of computer monitors
                        </InlineExternalLink>
                    </li>
                </ul>
                <PageBlockSubtitle>Careers</PageBlockSubtitle>
                <ul>
                    <li>
                        <InlineExternalLink href='https://unsplash.com/photos/KdeqA3aTnBY' rel='external nofollow'>
                            Photo of a meeting on a wooden table
                        </InlineExternalLink>
                    </li>
                </ul>
                <PageBlockSubtitle>Meet Our People</PageBlockSubtitle>
                <ul>
                    <li>
                        <InlineExternalLink href='https://getavataaars.com' rel='external nofollow'>
                            Avatars
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://app.haikei.app' rel='external nofollow'>
                            SVG Backgrounds
                        </InlineExternalLink>
                    </li>
                </ul>

                <BlockTitle>The Work</BlockTitle>
                <ul>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/close-up-photo-of-programming-of-codes-546819/' rel='external nofollow'>
                            Photo of screen with code
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink
                            href='https://www.pexels.com/photo/crop-technician-checking-contacts-on-motherboard-in-workshop-3825581/'
                            rel='external nofollow'
                        >
                            Photo of probing circuit board
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/cables-connected-to-ethernet-ports-2881232/' rel='external nofollow'>
                            Photo of blue ethernet cables
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/green-computer-circuit-board-159220/' rel='external nofollow'>
                            Photo of green PCB
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/black-samsung-tablet-computer-106344/' rel='external nofollow'>
                            Photo of black tablet with dashboard
                        </InlineExternalLink>
                    </li>
                </ul>

                <BlockTitle>Careers</BlockTitle>
                <PageBlockSubtitle>Benefits</PageBlockSubtitle>
                <ul>
                    <li>
                        <InlineExternalLink href='https://uxwing.com/design-thinking-icon/' rel='external nofollow'>
                            Design Thinking Icon
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://uxwing.com/remote-work-icon/' rel='external nofollow'>
                            Remote Work Icon
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://uxwing.com/financial-growth-icon/' rel='external nofollow'>
                            Financial Growth Icon
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://uxwing.com/personal-growth-icon/' rel='external nofollow'>
                            Personal Growth Icon
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://uxwing.com/information-technology-icon/' rel='external nofollow'>
                            Information Technology Icon
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://uxwing.com/globe-icon/' rel='external nofollow'>
                            Globe Icon
                        </InlineExternalLink>
                    </li>
                </ul>

                <ul>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/man-sitting-in-front-of-three-computers-4974915/' rel='external nofollow'>
                            Photo of man at home with multiple laptops
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/person-holding-orange-pen-1925536/' rel='external nofollow'>
                            Photo of person holding pen
                        </InlineExternalLink>
                    </li>
                </ul>

                <PageBlockSubtitle>Culture</PageBlockSubtitle>
                <ul>
                    <li>
                        <InlineExternalLink
                            href='https://www.pexels.com/photo/man-in-black-zip-up-jacket-holding-white-disposable-cup-4064850/'
                            rel='external nofollow'
                        >
                            Photo of a man in casual clothing with coffee working
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/photo-of-men-having-conversation-935949/' rel='external nofollow'>
                            Photo of men having conversation
                        </InlineExternalLink>
                    </li>
                </ul>

                <PageBlockSubtitle>Our People</PageBlockSubtitle>
                <ul>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/a-man-in-front-of-computer-screens-7431356/' rel='external nofollow'>
                            Photo of man in the dark in front of multiple monitors
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/close-up-view-of-system-hacking-in-a-monitor-5380664/' rel='external nofollow'>
                            Photo of monitor with multiple terminals
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/close-up-photo-of-person-typing-on-laptop-1181675/' rel='external nofollow'>
                            Photo of web dev on multiple monitors
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/man-person-smartphone-dirty-6755136/' rel='external nofollow'>
                            Photo of desoldering circuit board
                        </InlineExternalLink>
                    </li>
                </ul>

                <PageBlockSubtitle>Opportunities</PageBlockSubtitle>
                <ul>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/person-holding-white-scroll-2292837/' rel='external nofollow'>
                            Photo of a graduate holding out a white scroll
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink
                            href='https://www.pexels.com/photo/woman-filling-job-application-form-in-office-with-boss-5668858/'
                            rel='external nofollow'
                        >
                            Photo of a man and woman filling out a form
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/man-standing-infront-of-white-board-1181345/' rel='external nofollow'>
                            Photo of man thinking in front of whiteboard
                        </InlineExternalLink>
                    </li>
                </ul>

                <PageBlockSubtitle>Technical Specialist Framework</PageBlockSubtitle>
                <ul>
                    <li>
                        <InlineExternalLink
                            href='https://www.pexels.com/photo/formal-man-with-tablet-giving-presentation-in-office-3760093/'
                            rel='external nofollow'
                        >
                            Photo of man giving presentation
                        </InlineExternalLink>
                    </li>
                </ul>

                <BlockTitle>Contact</BlockTitle>
                <ul>
                    <li>
                        <InlineExternalLink href='https://www.pexels.com/photo/man-with-headphones-facing-computer-monitor-845451/' rel='external nofollow'>
                            Photo of man with headphone using computer
                        </InlineExternalLink>
                    </li>
                </ul>
            </PageBlock>
        </PageLayout>
    );
};

export default DisclaimerPage;
